@font-face {
  font-family: "nachelle";
  src: url("./fonts/Nacelle-Regular.otf");
}

.app {
  font-family: "nachelle";
  background: lightgray
}

.dis-c {
  width: 828px;
  padding: 56px 56px 60px 56px;

  border-radius: 24px;
  border: 5px solid #FFF;
  background: linear-gradient(180deg, #FCFCFC 57.67%, #FFF 100%);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.09);
}

.discover {
  color: #252525;
  text-align: center;
  font-family: "nachelle";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 24px */
}

.dis {
  color: #252525;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 38.4px */
}

.dis-2 {
  color: #252525;
  text-align: center;
  font-family: "nachelle";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  /* 150% */
  margin-bottom: 30px;
  opacity: 0.94;
  margin-top: 10px;
}

.image-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.slanted-image {
  margin-top: 20px;
  width: 15%;
  /* Adjust size as necessary */
  transform: rotate(15deg);
  /* Slant the image by 15 degrees */
  transform-origin: top;
  /* Control where the rotation happens from */
}

.ab {
  color: #252525;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.slanted-image-3 {
  width: 15%;
  /* Adjust size as necessary */
  transform: rotate(-5deg);
  /* Slant the image by 15 degrees */
  transform-origin: top;
  /* Control where the rotation happens from */
}

.slanted-image-opposite {
  margin-top: 20px;
  width: 15%;
  /* Adjust size as necessary */
  transform: rotate(-6deg);
  /* Slant the image by 15 degrees */
  transform-origin: top;
  /* Control where the rotation happens from */
}

.slanted-image-2 {
  width: 15%;
  /* Adjust size as necessary */
  transform: rotate(5deg);
  /* Slant the image by 15 degrees */
  transform-origin: top;
  /* Control where the rotation happens from */
}

.slanted {
  transform: rotate(-20deg);
  /* Slant the image by 15 degrees */
  transform-origin: top;
}

.slanted-sm {
  transform: rotate(-5deg);
  /* Slant the image by 15 degrees */
  transform-origin: top;
}

.slanted-opp-sm {
  transform: rotate(5deg);
  /* Slant the image by 15 degrees */
  transform-origin: top;
}

.slanted-opp {
  transform: rotate(20deg);
  /* Slant the image by 15 degrees */
  transform-origin: top;
}

.homepage-text {
  color: #000;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "nachelle";
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 72px */
}

.homepage-text-sm {
  color: #000;
  padding: 2rem;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "nachelle";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 72px */
}

.bg-image {}

.rp-img {
  height: 160px;
  width: 300px;
  position: relative;
  opacity: 0.76;
  transform: scaleY(10deg);
  transition: display 2s;

}

.bg-image-window {
  background: url("./images/Garnr/window.png") no-repeat;
}

.chat-bar {
  position: absolute;
  left: 200px;
  margin-top: 80px;
}

.chat-bar-message {
  position: absolute;
  left: 150px;
  height: 600;
  margin-top: 20px;
}

.searchq {
  position: absolute;
  left: 150px;
  margin-top: 330px;
}

.searchq-message {
  position: absolute;
  left: 200px;
  margin-top: 440px;
  height: 80px !important;
}

.change-position {
  position: absolute;
  width: 60%;
  object-fit: contain;
  margin-top: 40px;

}

.section-3-top {
  position: absolute;
  right: 180px;

}

.section-3-top-message {
  position: relative;
  left: 800px;
  width: 200px;
  top: 100px;
}

.text-container {
  position: absolute;
  right: 50px;
  margin-top: 200px;

}
.text-container-message {
  position: absolute;
  right: 180px;
  margin-top: 300px;
}
.multiple {
  position: absolute;
  right: 140px;
  margin-top: 430px;

}
.multiple-message {
  position: absolute;
  right: 165px;
  margin-top: 330px;
height: 70px !important;
}
.change-width {
  height: 20%;
  overflow: hidden;
  object-fit: cover;
}

.chatbar {
  height: 8%;
  object-fit: cover;

}

.last-section {
  border-radius: 16px;
  border: 1px solid #D1D5DB;
  opacity: 0.94;
  background: #FFF;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
}

.section-2-1 {
  color: #252525;
  text-align: center;
  font-family: "nachelle";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 24px */
}

.section-2-2 {
  color: #252525;
  text-align: center;
  font-family: "nachelle";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.section-2-2-1 {
  color: #252525;
  text-align: center;
  font-family: "nachelle";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.input-box {
  display: flex;
  width: 800px;
padding:10px 14px 10px 20px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 16px;
background: #FFF;
cursor: pointer;
box-shadow: 0px 5px 10px -3px rgba(107, 114, 128, 0.03), 0px 8px 18px 1px rgba(107, 114, 128, 0.04), 0px 3px 33px 2px rgba(107, 114, 128, 0.08);
}